// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-in-user-form-comp {
  padding: 20px;
}
.sign-in-user-form-comp .header {
  padding: 20px;
  font-family: Lato;
  font-weight: 300;
  margin: 0;
  color: #572c86;
}
.sign-in-user-form-comp .button-section {
  padding: 5px 20px 0 20px;
}
@media only screen and (min-width: 751px) {
  .sign-in-user-form-comp .header {
    font-size: 32px;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
