// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-details-panel-comp {
  padding: 0 20px;
}
.account-details-panel-comp .create-btn-wrapper {
  padding: 20px;
}
.account-details-panel-comp .terms-message {
  padding: 2px 0;
  font-size: 12px;
  font-style: italic;
  color: #7E90AA;
}
.account-details-panel-comp .create-button-wrapper {
  padding: 8px 0;
}
.account-details-panel-comp .password-section-header,
.account-details-panel-comp .currency-section-header {
  font-family: Lato;
  font-weight: 300;
  font-size: 22px;
  margin: 10px 0 5px;
  padding-left: 20px;
  color: #572c86;
}
.account-details-panel-comp .dropdown-wrapper {
  max-width: 350px;
}
@media only screen and (min-width: 751px) {
  .account-details-panel-comp {
    padding: 10px 40px;
    font-family: Lato;
  }
  .account-details-panel-comp .header {
    font-size: 32px;
  }
}
.account-details-panel-comp .create-error {
  max-width: 500px;
  margin-top: 20px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
