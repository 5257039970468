// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graphic-panel-comp {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 40px;
  justify-content: left;
  align-items: left;
  text-align: left;
}
.graphic-panel-comp .logo {
  width: 175px;
}
.graphic-panel-comp .graphic-panel-header {
  font-family: Lato;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  color: #572c86;
}
.graphic-panel-comp .content {
  font-size: 14px;
  font-family: Lato;
}
.graphic-panel-comp .panel-image {
  width: 215px;
  display: none;
}
@media only screen and (min-width: 751px) {
  .graphic-panel-comp {
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: radial-gradient(30px 40% at right center, rgba(207, 207, 207, 0.4), #ffffff);
  }
  .graphic-panel-comp .graphic-panel-header {
    font-size: 28px;
    width: 80%;
  }
  .graphic-panel-comp .content {
    margin-bottom: 25px;
  }
  .graphic-panel-comp .panel-image {
    display: block;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
