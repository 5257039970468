// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-layout-comp {
  height: calc(100% - 31px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-layout-comp .main-wrapper {
  max-height: 100%;
  max-width: 1350px;
  width: 100%;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
