// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.route-error-boundary-comp {
  margin-top: 40vh;
  transform: translateY(-50%);
  text-align: center;
}
.route-error-boundary-comp .route-error-boundary-stage {
  padding: 100px 10px;
}
.route-error-boundary-comp h2 {
  margin-top: 0;
  color: #572c86;
}
.route-error-boundary-comp h2 i {
  vertical-align: bottom;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
