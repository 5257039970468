// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-comp {
  height: 31px;
  box-sizing: border-box;
  background: #F4F7FA;
  font-family: Lato;
  font-size: 12px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
}
.footer-comp .footer-copyright {
  color: #586A87;
}
.footer-comp .footer-copyright .marketing-link {
  color: #7E90AA;
  text-decoration: none;
}
.footer-comp .footer-links > a {
  color: #007CDB;
  text-decoration: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
