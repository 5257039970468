// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 750px) {
  .logged-in-user-form-comp {
    padding: 10px 20px 0;
  }
}
.logged-in-user-form-comp .button-section {
  padding: 30px 20px 0 20px;
}
.logged-in-user-form-comp .admin-account-email {
  padding: 0 20px;
}
.logged-in-user-form-comp .change-account-button-wrapper {
  padding: 0 20px;
}
.logged-in-user-form-comp .currency-section-header {
  font-family: Lato;
  font-weight: 300;
  font-size: 22px;
  margin: 10px 0 5px;
  padding-left: 20px;
  color: #572c86;
}
.logged-in-user-form-comp .dropdown-wrapper {
  max-width: 250px;
}
.logged-in-user-form-comp .terms-message {
  padding: 2px 0;
  font-size: 12px;
  font-style: italic;
  color: #7E90AA;
}
.logged-in-user-form-comp .create-button-wrapper {
  padding: 8px 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
