// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-user-details-comp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}
.new-user-details-comp .user-details-wrapper {
  text-align: center;
}
.new-user-details-comp .user-details-wrapper .user-details-header {
  font-family: Lato;
  font-weight: bold;
  font-size: 38px;
  margin: 15px 0 10px;
  color: #572c86;
}
.new-user-details-comp .user-details-wrapper .user-details-message {
  font-family: Lato;
  font-size: 16px;
  margin-bottom: 30px;
}
.new-user-details-comp .user-details-wrapper .password-section-header {
  font-family: Lato;
  font-weight: 300;
  font-size: 22px;
  margin-bottom: 10px;
  color: #572c86;
}
.new-user-details-comp .user-details-wrapper .logo {
  width: 175px;
}
.new-user-details-comp .user-details-wrapper .create-account-btn-wrapper {
  margin: 20px 0;
}
.new-user-details-comp .create-error {
  margin: 20px 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
