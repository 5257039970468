// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-section-comp .password-section-header {
  font-family: Lato;
  font-weight: 300;
  font-size: 22px;
  margin: 0;
  color: #572c86;
}
.password-section-comp .section-info {
  font-family: Lato;
  font-size: 14px;
  padding: 0 20px 20px 20px;
}
.password-section-comp .password-validation-wrapper {
  font-family: Lato;
  font-size: 12px;
  color: #A7B7CB;
}
.password-section-comp .validation-conditions {
  list-style: none;
  padding-left: 20px;
  letter-spacing: 1px;
}
.password-section-comp .validation-list-item {
  color: #A7B7CB;
  padding-top: 5px;
}
.password-section-comp .validation-list-item::before {
  font-family: Gocious-Icons !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\\e907";
  display: inline-block;
  padding-right: 10px;
  color: #a00;
}
.password-section-comp .validation-list-item.is-valid {
  color: #586A87;
}
.password-section-comp .validation-list-item.is-valid::before {
  font-family: Gocious-Icons !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\\e922";
  display: inline-block;
  padding-right: 8px;
  color: #8CC641;
}
.password-section-comp .password-input-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  min-height: 28px;
}
.password-section-comp .password-input-wrapper input {
  min-height: 28px;
}
.password-section-comp .password-input-wrapper .view-password-icon,
.password-section-comp .password-input-wrapper .hide-password-icon {
  color: #069DA8;
  font-size: 22px;
  position: relative;
  top: 7px;
  margin-left: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.password-section-comp .password-input-wrapper .view-password-icon:hover,
.password-section-comp .password-input-wrapper .hide-password-icon:hover {
  color: #F26722;
}
.password-section-comp .password-input-wrapper .check-mark-wrapper {
  font-family: Lato;
  font-size: 14px;
  position: relative;
  top: 8px;
  letter-spacing: 0.5px;
}
.password-section-comp .password-valid-icon {
  font-size: 16px;
  color: #ffffff;
  background: #8CC641;
  border-radius: 50%;
  margin-left: 10px;
  padding: 5px;
}
.password-section-comp.center-justified .password-input-wrapper {
  justify-content: center;
  padding-left: 63px;
}
.password-section-comp.center-justified .password-input-wrapper input {
  padding: 0;
}
.password-section-comp.center-justified .password-input-wrapper input,
.password-section-comp.center-justified .password-input-wrapper div {
  text-align: center;
}
.password-section-comp.center-justified .check-mark-wrapper {
  min-width: 38px;
}
.password-section-comp.center-justified .password-validation-wrapper {
  text-align: left;
  padding-top: 20px/2;
}
.password-section-comp.center-justified .validation-conditions {
  padding: 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
