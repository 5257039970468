// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-details-comp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.account-details-comp .graphic-panel-wrapper {
  width: 100%;
  padding-top: 40px;
}
.account-details-comp .account-details-panel-wrapper {
  width: 100%;
}
@media only screen and (min-width: 751px) {
  .account-details-comp {
    flex-direction: row;
  }
  .account-details-comp .graphic-panel-wrapper {
    padding-top: 0px;
  }
  .account-details-comp .graphic-panel-wrapper,
  .account-details-comp .account-details-panel-wrapper {
    width: 50%;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
