// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-user-form-comp {
  padding: 20px;
}
.new-user-form-comp .email-panel-header {
  padding: 20px;
  font-family: Lato;
  font-weight: 300;
  margin: 0;
  color: #572c86;
}
.new-user-form-comp .input-wrapper {
  max-width: 500px;
  flex: 1 1;
}
.new-user-form-comp .input-wrapper input {
  min-height: 28px;
}
.new-user-form-comp .input-wrapper.code {
  max-width: 300px;
}
.new-user-form-comp .button-section {
  padding: 0 20px;
}
.new-user-form-comp .existing-user-button-wrapper {
  padding: 0 20px 20px;
}
.new-user-form-comp .existing-user-button-wrapper > a {
  font-size: 12px;
  color: #007CDB;
  text-decoration: none;
}
.new-user-form-comp .existing-user-button-wrapper > a:hover,
.new-user-form-comp .existing-user-button-wrapper > a:focus {
  text-decoration: underline;
  color: #F26722;
}
.new-user-form-comp .privacy-link {
  font-size: 12px;
  padding: 20px 20px 10px;
}
@media only screen and (min-width: 751px) {
  .new-user-form-comp .email-panel-header {
    font-size: 32px;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
