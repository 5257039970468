// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subdomain-section-comp .subdomain-section-header {
  padding: 20px 20px 20px/2 20px;
  font-family: Lato;
  font-weight: 300;
  font-size: 32px;
  margin: 0;
  color: #572c86;
}
@media only screen and (max-width: 750px) {
  .subdomain-section-comp .subdomain-section-header {
    display: none;
  }
}
.subdomain-section-comp .section-info {
  font-family: Lato;
  font-size: 14px;
  padding: 0 20px 20px 20px;
}
.subdomain-section-comp .subdomain-available-icon {
  font-size: 16px;
  color: #ffffff;
  background: #8CC641;
  border-radius: 50%;
  margin-left: 10px;
  padding: 5px;
}
.subdomain-section-comp .url-input-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}
.subdomain-section-comp .url-input-wrapper input {
  min-height: 28px;
}
.subdomain-section-comp .url-input-wrapper .gocious-domain,
.subdomain-section-comp .url-input-wrapper .check-mark-wrapper {
  font-family: Lato;
  font-size: 14px;
  position: relative;
  top: 8px;
  letter-spacing: 0.5px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
